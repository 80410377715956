import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  constructor() { }



  bodyJsonLogin: any 



  setBodyJsonLogin(value: any){
    this.bodyJsonLogin = value;
  }

  getBodyJsonLogin(){
    return this.bodyJsonLogin;
  }
}
