import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import{HttpClientModule} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PregRespComponent } from './components/preg-resp/preg-resp.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import { ErrorComponent } from './components/error/error.component';
import { HomeComponent } from './components/home/home.component';
import { CookiesPolComponent } from './components/cookies-pol/cookies-pol.component';
import { TermCondicionesComponent } from './components/term-condiciones/term-condiciones.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './components/login/login.component';
import { ServicesService } from './services/service.service';
import { CookieService } from 'ngx-cookie-service';


 
@NgModule({
  declarations: [
    AppComponent,
    PregRespComponent,
    ContactoComponent,
    ErrorComponent,
    HomeComponent,
    CookiesPolComponent,
    TermCondicionesComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [ServicesService,CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
