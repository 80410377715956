import { Component, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import {Location} from '@angular/common';
import { FormVehComponent } from './auto/form-veh/form-veh.component';
import { ViewChildCompService } from './auto/services/view-child-comp.service';
import { AutoService } from './auto/services/auto.service';
import { LoginComponent } from './components/login/login.component';
import { ServicesService } from './services/service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  

  title = 'asegurate';
  showMenu:boolean=true;
  whatsUs:string='https://api.whatsapp.com/send?phone=18096202524&text=Hola!, necesito tu ayuda con el proceso para asegurarme en ASEGURATE.COM.DO';
  bodyJsonLogin: any = null
  serviceA:any;

  constructor( 
    public autoService: AutoService,
    public router: Router,    
    public location: Location,
    public servicesService: ServicesService,
    private listComp: ViewChildCompService 
  ) { }    
    

  ngOnInit(): void {
    let local = localStorage.getItem('bodyJsonLogin')
    this.bodyJsonLogin = local ? JSON.parse(local) : null;

    if(sessionStorage.getItem('raload')=== 'true'){
     this.resetEl(); 
    }
    if(this.location.path().slice(0, 11)=='/inspeccion'){
      this.showMenu =false;
    }
  } 

  @ViewChild(FormVehComponent) inpsViewChild: FormVehComponent;
  ngAfterViewInit(){
   // console.log("aaaawee")
    this.autoService.Interaccion(0,"Se entro a la pantalla de Productos (Home)", 0)
    if(sessionStorage.getItem('raload')=== 'true'){
      this.resetEl(); 
     }
    if(this.location.path().slice(0, 11)=='/inspeccion'){
      this.showMenu =false;
    }
  }
  resetEl(){
    sessionStorage.removeItem('raload');
    location.reload();  
  }

  

  //Go wassap
goWhatapp(){
  window.open(this.whatsUs, "_blank");
 }


 @ViewChild(LoginComponent) loginComponent!: LoginComponent;

  // Método para abrir el modal
  openLoginModal() {
    this.loginComponent.show();
  }




  isDropdownOpen: boolean = false;

  toggleDropdown() {

    //setTimeout(() => {
      this.isDropdownOpen = !this.isDropdownOpen;
    //}, 100);
  

    
  }


  logout() {
    //console.log('Cerrando sesión...');
    this.loginComponent.LogOut()
    // Aquí puedes agregar la lógica de cierre de sesión
  }
}
