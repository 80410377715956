import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PregRespComponent } from './components/preg-resp/preg-resp.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import { ErrorComponent } from './components/error/error.component';
import { HomeComponent } from './components/home/home.component';
import { CookiesPolComponent } from './components/cookies-pol/cookies-pol.component';
import { TermCondicionesComponent } from './components/term-condiciones/term-condiciones.component';
import { InspeccionComponent } from './auto/inspeccion/inspeccion.component';
import { LoginComponent } from './components/login/login.component';

const routes: Routes = [
  {path:'',component:HomeComponent },
  {path:'preguntas-frecuentes', component:PregRespComponent},
  {path:'contacto', component:ContactoComponent},
  {path:'politicas-de-cookies', component:CookiesPolComponent},
  {path: 'terminos-y-condiciones', component:TermCondicionesComponent},
  {path: 'Auto', loadChildren: () => import('./auto/auto.module').then(m => m.AutoModule) },
  {path: 'inspeccion', component:InspeccionComponent},
  {path:'login', component:LoginComponent },
  {path:'**', component:ErrorComponent }
  //{path: 'Auto/:id', loadChildren: () => import('./auto/auto.module').then(m => m.AutoModule)}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
