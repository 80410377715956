import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AutoService {

  fillFormOne:boolean=false;
  fillFromThree:boolean=false;
  EquipamientosSaved: number[] = []
  FormVehiculoPrueba: any
  mostrarApellido: boolean;
  IntermediariosList: any;
  tipoDoc: string;
  IpUsuario: string
  apellidoCont: string
  esPrimeraVez: boolean = false
  step: number
  TieneIntermediario = true
  aseguradoraIndex: number

  response:any;
  errorMessage:any;

  //todo le objecto de datos
  allTheData:any={
    ClienteId: 0,
    Plan: '',
    numeroCotz: "",
    TipoPago: "",
    TipoSeguro: "",
    MontoPagado: 0,
    Intermediario: 1,
    PlanMeses: 12,
    estatus: "inspeccionar",
    Cliente : {
      tipoDocumento: "",
      nombre : "",
      apellido : "",
      correo : "",
      telefono : "",
      identificacion : "",
      Aseguradora: "",
      Pendiente: false,
      IdCotizacion: "",
      IpUsuario: ""
    },
    Pago : {
      nombre : "",
      apellido: "",
      numero: "",
      mm: "",
      aa: "",
      cvv: ""
    },
    Auto : {
      cotz_id:0,
      aseguradora : "",
      marca: "",
      modelo: "",
      chasis: "",
      placa: "",
      tipo_vehiculo: "",
      passcode:"",
      Anio: "",
      ValorVehiculo: "",
      Circulacion: "",
      Uso: "",
      Equipamientos:[""]
    },
    CoberturasData:[],
    CoberturasOpcionales:[]
  };



   // Datos con los valores
  allDataParamers:any ={
    id_tipoAuto: "",
    TipoSeguro: "",    
    passcode:"",
    Auto: {
      tipo_vehiculo: "",
      marca: "",
      modelo: "",
      Anio: "",      
      ValorVehiculo: "",
      Circulacion: "",
      Uso: "",
      Equipamientos:[]
    },
    Intermediario: 1
  }

  DatosPago: any = {
    Numero: "1111111111111111",
    FechaMM: "01",
    FechaAA: (new Date().getFullYear() + 1).toString().substring(2,4), //Anio recurrente + 1
    CVV: "1111",
    Nombre: "cliente"
  }

  //Datos Adicionales para el resumen de compra
  otraData:any={
    urlImgAsegurado: '',
    numCuotas:'',
    pagosRecurentes:'',
    TotalPagar:''
  }
  // para mostrar el texto de los equipamentos 
  equipamientosText:any=[];

  prueba2={
    "id_tipoAuto": "1",
    "Plan": 'FULL',
    "numeroCotz": "113819",
    "TipoPago": "Unico",
    "TipoSeguro": "LEY",
    "MontoPagado": 21640,
    "estatus": "inspeccionar",
    "Cliente": {
        "nombre": "Rosa Vanessa",
        "apellido": "Cordero Ozoria",
        "correo": "ing.soft.cordero@gmail.com",
        "telefono": "8092179600",
        "identificacion": "00119024172"
    },
    "Pago": {
        "nombre": "Rosa Cordero",
        "apellido": "",
        "numero": "3333333333333333",
        "mm": "12",
        "aa": "23",
        "cvv": "2345"
    },
    "Auto": {
        "aseguradora": "Angloamericana",
        "marca": "Acura",
        "modelo": "Integra",
        "chasis": "87489441555555516",
        "placa": "2222222",
        "tipo_vehiculo": "Auto/St. W.",
        "passcode":"6 cilindros",
        "Anio": "2015",
        "ValorVehiculo": 500000,
        "Circulacion": "DISTRITO NACIONAL",
        "Uso": "PRIVADO",
        "Equipamientos": ["01"]
    },
    "CoberturasData": [
        {"Cobertura":"Asistencia en viajes","Valor":"Incluido"},{"Cobertura":"Centro del automovilista (CA)","Valor": "Incluido"},{"Cobertura":"Cobertura comprensiva","Valor":"100%"},{"Cobertura":"Colisión y/o vuelco","Valor":"100%"},{"Cobertura":"Daños a la propiedad ajena","Valor": "500,000"},{"Cobertura":"Deducible","Valor":"1%"},{"Cobertura":"Fianza judicial","Valor": "1,000,000"},{"Cobertura": "Incendio y/o robo","Valor": "100%"},{"Cobertura": "Lesiones y/o muerte 1 pasajero","Valor":"500,000"},{"Cobertura":"Lesiones y/o muerte 1 persona","Valor":"500,000"},{"Cobertura":"Lesiones y/o muerte mas de 1 pasajero","Valor":"1,000,000"},{"Cobertura":"Lesiones y/o muerte mas de 1 persona","Valor":"1,000,000"},{"Cobertura":"Plan renta","Valor":"Incluido"},{"Cobertura":"Riesgo conductor","Valor":"50,000"},{"Cobertura":"Rotura de cristales","Valor":"10%"},{"Cobertura":"Últimos gastos","Valor": "50,000"},{"Cobertura":"Vida (Cubre el saldo insoluto hasta)","Valor":"300,000"}]}

  constructor( private http: HttpClient) { }

    /******************
   *      paso 1
    *****************/
   fillFormVeh(){return this.fillFormOne;}

  /* Guardar los datos del paso uno - form vehiculo */
  stepOne(marca, modelo, tipVeh, passcod, anio, valor, circulacion, uso, equip,equipaText, tipoSeg, intermediario){
    this.allTheData.TipoSeguro= tipoSeg;    
    this.allTheData.Auto.marca= marca;
    this.allTheData.Auto.modelo= modelo;
    this.allTheData.Auto.tipo_vehiculo= tipVeh;    
    this.allTheData.Auto.passcode= passcod;    
    this.allTheData.Auto.Anio= anio;
    this.allTheData.Auto.ValorVehiculo= valor;
    this.allTheData.Auto.Circulacion= circulacion;
    this.allTheData.Auto.Uso= uso;
    this.allTheData.Auto.Equipamientos= equip;
    this.equipamientosText=equipaText;
    this.allTheData.Intermediario = intermediario;
    //console.log(this.allTheData)
  };

  SetDatosCotizacion(aseguradora, idcotz){
    //console.log(idcotz)
    this.allTheData.Auto.cotz_id=idcotz
    this.allTheData.Cliente.IdCotizacion=idcotz.toString()
    this.allTheData.Cliente.Aseguradora = aseguradora

    //console.log(this.allTheData)
  }

  setAseguradoraIndex(index){
    this.aseguradoraIndex = index;
  }
  getAseguradoraIndex(){
    return this.aseguradoraIndex;
  }


  // Ver el texto de los equipamentos
  equipText(){
    return this.equipamientosText;
  }
  /* Guardar los datos del form vehiculo para llenarlo si va  atras */
  saveData(id_auto, marca, modelo, tipVeh, passcod, anio, valor, circulacion, uso, equip, tipoSeg, step, intermediario = 1, TieneIntermediario){
    this.allDataParamers.id_tipoAuto=id_auto;
    this.allDataParamers.TipoSeguro= tipoSeg;    
    this.allDataParamers.Auto.marca= marca;
    this.allDataParamers.Auto.modelo= modelo;
    this.allDataParamers.Auto.tipo_vehiculo= tipVeh;    
    this.allDataParamers.passcode= passcod;    
    this.allDataParamers.Auto.Anio= anio;
    this.allDataParamers.Auto.ValorVehiculo= valor;
    this.allDataParamers.Auto.Circulacion= circulacion;
    this.allDataParamers.Auto.Uso= uso;
    this.allDataParamers.Auto.Equipamientos= equip;
    this.step = step
    this.allDataParamers.Intermediario = intermediario;
    this.TieneIntermediario = TieneIntermediario;
    //console.log(this.allDataParamers.Auto.Equipamientos)
  };

  /* Mostrar los datos llenos */
  getDataVeh(){
    return this.allDataParamers;
  };

  /* Cambiar el valor a true para que llene el formulario de vehiculo si va atras */
  fillVeh(){
    this.fillFormOne=true;
  }
  
  /******************
   *      Paso2
    *****************/

  /*ver los datos ingresados del primer formulario para mostrarse en el paso dos "Datos Ingresados"*/
  seeDataForm(){
    const datosIngresados={
      TipoSeguro: this.allTheData.TipoSeguro,
      TipoVehiculo: this.allTheData.Auto.tipo_vehiculo,
      Passcode: this.allTheData.Auto.passcode,
      Marca: this.allTheData.Auto.marca,
      Modelo:this.allTheData.Auto.modelo,
      Anio:this.allTheData.Auto.Anio,
      ValorVehiculo: this.allTheData.Auto.ValorVehiculo,
      Provincia:this.allTheData.Auto.Circulacion,
      Uso:this.allTheData.Auto.Uso,
      Equipamientos:this.equipamientosText,
      Intermediario: this.allTheData.Intermediario
    }
    return datosIngresados;
  };

  ofertas(info:any){
    this.response=info;}

  /*Enviar el objeto con todas las ofertar*/
  seeOfer(){ 
    return  this.response;}

  /* ejemplo de la funcion de arriba "Datos Ingresados" */
  seeDataFormEjemplo(){
    const datosIngresados={
      tipoSeguro: "FULL",
      tipo_vehiculo: "Auto/St. W.",
      passcode: "6 cilindros",
      marca: "TOYOTA",
      modelo: "4RUNNER",
      anio: 2015,
      valor: 500000,
      provincia: "DISTRITO NACIONAL",
      uso:"PRIVADO",
      equipamientos:["GAS NATURAL", "Cambio de guia"]
    }
    return datosIngresados;
  };

  /* Guardar los datos del paso dos - comparador */
  stepTwo(plan, numCoti, tipoPag, montPag, asegur, cobert,url,pago, otrosPag=0, cuotas='', equipamientos= []){
    this.allTheData.Plan= plan;
    this.allTheData.numeroCotz= numCoti;    
    this.allTheData.TipoPago= tipoPag;
    this.allTheData.MontoPagado= montPag;
    this.allTheData.Auto.aseguradora= asegur;    
    this.allTheData.CoberturasData= cobert;
    this.otraData.pagosRecurrentes= otrosPag;
    this.otraData.urlImgAsegurado=url;
    this.otraData.numCuotas=cuotas;
    this.otraData.TotalPagar=pago;
    this.allTheData.Auto.Equipamientos = this.allTheData.Auto.Equipamientos.length === 0 ? equipamientos : this.allTheData.Auto.Equipamientos
    this.equipamientosText = equipamientos
    //console.log(this.allTheData)
  };

  LlenarAllTheData(data: any){
    this.allTheData = data
    //console.log(this.allTheData)
  }

    /******************
    *     Paso 3     *
    *****************/

  /* Guardar los datos del paso tres - form vehiculo */
  stepThree(tipoDocumento,nombre,apellido,correo,telefono,identificacion,nombreTC,numero,mm, aa,cvv, chasis, placa=''){
    this.allTheData.Cliente.tipoDocumento = tipoDocumento;
    this.allTheData.Cliente.nombre= nombre;    
    this.allTheData.Cliente.apellido= apellido;
    this.allTheData.Cliente.correo= correo;
    this.allTheData.Cliente.telefono= telefono;    
    this.allTheData.Cliente.identificacion= identificacion;
    this.allTheData.Pago.nombre= nombreTC;    
    this.allTheData.Pago.numero= numero;
    this.allTheData.Pago.mm=  mm;
    this.allTheData.Pago.aa= aa;
    this.allTheData.Pago.cvv= cvv;
    this.allTheData.Auto.chasis=chasis;
    this.allTheData.Auto.placa= placa;
    this.tipoDoc = tipoDocumento;
  };

  /* Cambiar el valor a true para que llene el formulario de vehiculo si va atras */
  seeUser(){
    this.fillFromThree=true;
  }
  fillFormUser(){return this.fillFromThree;}

  seeAllObjectParameter(){
    //console.log(this.allTheData)
    return this.allTheData;
  }

   /******************
   *      Paso 4
    *****************/
  verOtraInfo(){
    return this.otraData;
  }

  verPruebaData(){
    return this.prueba2;
  }

  setStatus(status:string){
    this.allTheData.estatus=status;
  }


  Interaccion(id,mensaje, step){

    //console.log(this.allTheData)
    this.http
        .get(
          environment.baseURL + '/Interaccion?id='+id+'&mensaje=' +mensaje+ '&step='+step
        )
        .subscribe({
          next: (data) => {

          },
          error: (error) => {

          },
        });
  }


  bodyJsonLogin: any

  getBodyJsonLogin(){

    return this.bodyJsonLogin;
  }

  setBodyJsonLogin(value: any){
    this.bodyJsonLogin = value;
    //console.log("123123", this.bodyJsonLogin)
  }
}
