import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AutoService } from 'src/app/auto/services/auto.service';

@Component({
  selector: 'app-term-condiciones',
  templateUrl: './term-condiciones.component.html',
  styleUrls: ['./term-condiciones.component.css']
})
export class TermCondicionesComponent implements OnInit {

  constructor(private http: HttpClient, private autoService: AutoService) { }

  ngOnInit(): void {
    this.autoService.Interaccion(0,"El usuario visito el apartado de Termino y Condiciones",0)

  }

}
