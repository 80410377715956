import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AutoService } from 'src/app/auto/services/auto.service';
import {Output,EventEmitter,Input} from '@angular/core';
import { environment } from '../../../environments/environment';
import Swal from 'sweetalert2';
import { ServicesService } from 'src/app/services/service.service';
import { CookieService } from 'ngx-cookie-service';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private autoService: AutoService,
    private servicesService: ServicesService,
    private activatedRoute: ActivatedRoute,
    private cookieService: CookieService,
    private router: Router
  ) { }


  isModalOpen:boolean = false;
  formLogin: FormGroup
  submitted = false;
  botonEnable = false;
  loading: boolean = false;

  @Input()
  @Output()
  formLoginTalk: EventEmitter<any> = new EventEmitter();
  ngOnInit(): void {
    this.formLogin = this.formBuilder.group({
      // usuario: ['',[Validators.required, Validators.email]],
       usuario: ['',Validators.required],
      contra: ['', Validators.required]
    });

    




  }

  CerrarModal() {
    this.isModalOpen = false;
    this.formLoginTalk.emit('false');
    this.formLogin.reset()
    this.f.usuarios.setValue("")
    this.f.contra.setValue("")
    this.botonEnable = true;
  }
  get f() {
    return this.formLogin.controls;
  }

  validateInput(status, elemento) {
    if (status) {
      this.formLogin.controls[elemento].setValidators([Validators.required]);
      this.formLogin.controls[elemento].updateValueAndValidity();
    } else {
      this.formLogin.controls[elemento].setValidators(null);
      this.formLogin.controls[elemento].updateValueAndValidity();
    }
  }



  onSubmit() {
    this.submitted = true;

    if (this.formLogin.invalid) {
      return;
    } 
    else 
    {
      this.Logearse(this.formLogin.value.usuario, this.formLogin.value.contra);
    }
    
  }


  show() {
    this.isModalOpen = true;
  }

  hide() {
    this.isModalOpen = false;
  }

  Logearse(usuario: string, contra:string){
    this.loading = true
    this.botonEnable = true;
    const body = 
    {
      "_nombre": usuario,
      "_clave": contra
    }
      //console.log(body);
    
    this.http.post(environment.urlServiciosApi + '/Login', body).subscribe({
      next: (data:any) => {
        //console.log(data)
        
        localStorage.setItem('bodyJsonLogin', JSON.stringify(data));
        this.servicesService.setBodyJsonLogin(data)
        
        this.isModalOpen = false
        this.botonEnable = false;
        this.f.usuario.setValue("")
        this.f.contra.setValue("")

       this.cookieService.set("tk", data.response.claims._token)
       localStorage.setItem("tk", data.response.claims._token)
       this.cookieService.set("rtk", data.response.claims._refreshToken)
       this.cookieService.set("tm", data.response.claims._tiempo)

       this.router.navigate(['']).then(() => {
        window.location.reload();
      });
      this.loading = false
      },
      error: error => {
        this.whatErroIs(error)
        this.botonEnable = false;
        this.loading = false
      }
    })



  }


  LogOut(){
    //this.cookieService.deleteAll();
    this.cookieService.delete("tk");
    this.cookieService.delete("rtk");
    this.cookieService.delete("tm");
    localStorage.removeItem('bodyJsonLogin');
    localStorage.removeItem('tk');
    this.router.navigate(['']).then(() => {
      window.location.reload();
    });
  }


  EnableButton(){
    if(this.formLogin.valid){
      this.botonEnable = false;
    }
    else{
      this.botonEnable = true;
    }
  }

  whatErroIs(error) {
    switch (error.status) {
      case 400: {
        this.alert('Usuario o contraseña incorrectos.');
        break;
      }
      case 401: {
        this.alert('Usuario o contraseña incorrectos.');
        break;
      }
      case 500: {
        this.alert(
          'Ha ocurrido un error. Intentelo más tarde.'
        );
        break;
      }
      default: {
        this.alert('Ha ocurrido un error. Intentelo más tarde.');
        break;
      }
    }
  }

  alert(mensaje: string) {
    Swal.fire({
      icon: 'error',
      title: 'Oops... Algo salio mal',
      text: mensaje,
    });
  }
}
