<div *ngIf="isModalOpen" class="modal-overlay" (click)="CerrarModal()">
    <div class="modal-content" (click)="$event.stopPropagation()">

            <div class="modal-header">
                <img id="close" src="assets/img/close.svg" alt="Cerrar modal" title="Cerrar" (click)="CerrarModal()">
                <!-- <span id="close" (click)="CerrarModal()"><i class="bi bi-x-lg"></i></span> -->
                <img id="logo" src="assets/img/logo.svg" alt="Logo Asegurate.do" title="Asegurate.do">
            </div>

            <div class="modal-body">
                <form [formGroup]="formLogin" (ngSubmit)="onSubmit()">

                    <label id="texto">Ingresa tu usuario</label>
                    <div class="input-container">
                        <input type="text" id="usuario" id="usuario" placeholder="" formControlName="usuario"
                            [ngClass]='{"is-invalid": submitted && f.usuario.errors}' (input)="EnableButton()" />
                            
                        <label for="usuario">Usuario*</label>
                    </div>


                    <div class="input-container">
                        <input type="password" id="contra" placeholder="" formControlName="contra"
                            [ngClass]='{"is-invalid": submitted && f.contra.errors}' (input)="EnableButton()" />
                        <label for="contra">Contraseña*</label>
                    </div>

                    <div class="modal-buttons">
                        <button type="submit" class="submit-btn" [disabled]="botonEnable">
                          Iniciar sesión 
                          <div class="spinner-border spinner-border-sm" role="status" *ngIf="loading">
                            <span class="sr-only"></span>
                        </div>
                        </button>
                    </div>
                </form>
            </div>

    </div>
</div>